<template>
  <!-- 定位 -->
  <div>
    <div class="map_content">
      <div class="map" id="map"></div>
      <div class="map_info" id="map_info">
        <div class="info_item">
          <div id="searchBar">
            <input id="searchInput" v-model="searchAddress" placeholder="输入关键字搜素POI" />
          </div>
          <div id="searchResults"></div>
        </div>
        <div class="info_item">
          <input type='radio' name='mode'  value='dragMap' checked>拖拽地图
        </div>
        <div class="info_item">
          <input type='radio' name='mode'  value="dragMarker">拖拽Marker
        </div>
        <div class="info_item">
          <div class="info_l">地址：</div>
          <div id="address">{{address}}</div>
        </div>
        <div class="info_item">
          <div class="info_l">经度：</div>
          <div  id="longitude">{{longitude}}</div>
        </div>
        <div class="info_item">
          <div class="info_l">纬度：</div>
          <div id="latitude">{{latitude}}</div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancelMap" class="operBtn">取 消</el-button>
      <el-button type="primary" @click="confirmMap" class="operBtn">确 定</el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: ['Address', 'EditAddress'],
  data () {
    return {
      searchAddress: '',
      address: '',
      longitude: '',
      latitude: '',
      adminplan: '',
      map: null,
      editInfo: '',
      positionPicker: ''
    }
  },
  watch: {
    Address: {
      immediate: true,
      handler: function (value) {
        this.searchAddress = value
      }
    },
    EditAddress: {
      immediate: true,
      handler: function (value) {
        this.editInfo = value
        this.searchAddress = value.address
      }
    },
    searchAddress: {
      immediate: true,
      handler: function (value) {
        setTimeout(() => {
          this.enterSearch(value)
        }, 500)
      }
    }
  },
  beforeDestroy () {
    this.searchAddress = ''
  },
  created () {
    // this.startMap()
    // this.enterSearch()
  },
  mounted () {
    this.startMap()
    // this.getLocal()
  },
  methods: {
    getLocal () {
      var map, geolocation
      map = new AMap.Map('container', {
        resizeEnable: true
      })
      map.plugin('AMap.Geolocation', function() {
        geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认:true
          timeout: 10000, // 超过10秒后停止定位，默认：无穷大
          buttonPosition: 'RB'
        })
        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', function onComplete (data) {
          var lng = data.position.lng
          var lat = data.position.lat
        })
      })
    },
    enterSearch () {
      const that = this
      AMap.service(['AMap.PlaceSearch'], function () {
        var placeSearch = new AMap.PlaceSearch({
          city: '大荔县',
          map: that.map,
          autoFitView: true
        })
        placeSearch.search(that.searchAddress)
        that.address = that.editInfo.address
        that.longitude = that.editInfo.longitude
        that.latitude = that.editInfo.latitude
      })
    },
    startMap () {
      let that = this
      AMapUI.loadUI(['misc/PositionPicker'], function (PositionPicker) {
        var map = new AMap.Map('map', {
          resizeEnable: true,
          zoom: 16,
          scrollWheel: true
        })
        that.map = map
        that.positionPicker = new PositionPicker({
          mode: 'dragMap', // 设定为拖拽地图模式，可选'dragMap'、'dragMarker'，默认为'dragMap'
          map: map, // 依赖地图对象
          input: 'pickerInput' // 输入框id
        })
        // TODO:事件绑定、结果处理等
        that.positionPicker.on('success', function (positionResult) {
          console.log(positionResult)
          that.address = positionResult.address
          that.longitude = positionResult.position.lng
          that.latitude = positionResult.position.lat
          const adm1 = positionResult.regeocode.addressComponent.province
          const adm2 = positionResult.regeocode.addressComponent.city
          const adm3 = positionResult.regeocode.addressComponent.district
          const adm4 = positionResult.regeocode.addressComponent.township
          let str = ''
          str += adm1 + '/' + adm2 + '/' + adm3 + '/' + adm4
          that.adminplan = str
        })
        var onModeChange = function (e) {
          that.positionPicker.setMode(e.target.value)
        }
        that.positionPicker.start()
        map.panBy(0, 1)
        var dragMapMode = document.getElementsByName('mode')[0]
        var dragMarkerMode = document.getElementsByName('mode')[1]

        dragMapMode.addEventListener('change', onModeChange)
        dragMarkerMode.addEventListener('change', onModeChange)
        AMap.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], function () {
          // 实例化Autocomplete
          var autoOptions = {
            // input 为绑定输入提示功能的input的DOM ID
            input: 'searchInput'
          }
          var autoComplete = new AMap.Autocomplete(autoOptions)
          // 无需再手动执行search方法，autoComplete会根据传入input对应的DOM动态触发search
          var placeSearch = new AMap.PlaceSearch({
            city: '西安',
            map: map,
            autoFitView: true
          })
          AMap.event.addListener(autoComplete, 'select', function (e) {
            // TODO 针对选中的poi实现自己的功能
            placeSearch.search(e.poi.name)
            that.longitude = e.poi.location.lng
            that.latitude = e.poi.location.lat
            map.setZoomAndCenter(15, [e.poi.location.lng, e.poi.location.lat])
          })
        })
      })
    },
    // 定位确定
    confirmMap () {
      var _data = {
        address: this.address,
        longitude: this.longitude,
        latitude: this.latitude,
        adminplan: this.adminplan
      }
      this.$emit('save', _data)
    },
    // 点击取消
    cancelMap () {
      this.$emit('close')
    }
  }
}
</script>
<style>
.map_content{
  width: 100%;
  display: flex;
  overflow: hidden;
}
.map{
  width: 70%;
  /* float: left; */
  height: 500px;
}
.map_info{
  color: #444;
  background-color: #f8f8f8;
  width: 30%;
  /* float: left; */
  height: 500px;
  padding: 10px;
  box-sizing: border-box;
}
.info_item{
  width: 100%;
  margin-bottom: 15px;
  display: flex;
}
.info_l{
  min-width: 50px;
}
.dialog-footer{
  text-align: right;
  margin-top: 10px;
}
#searchBar {
  height: 30px;
  background: #ccc;
}

#searchInput {
  width: 100%;
  height: 30px;
  line-height: 30%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0 5px;
}

#searchResults {
  overflow: auto;
  height: calc(100% - 30px);
}
.amap-sug-result {
  z-index: 3000;
}
.operBtn{
  width: 90px;
  height: 35px;
  padding: 0px;
  line-height: 3px;
}
</style>
